import { computed } from 'vue'
import settings from '@/lib/settings'
import {breakDuration} from '@/lib/duration'

// Short Date Formatter
//
// Wed 5:00:00 PM
//
const shortDateFormatter = new Intl.DateTimeFormat('en-US', {
  weekday: 'short',
  // day: 'numeric', 
  hour: 'numeric', 
  minute: '2-digit', 
  second: '2-digit', 
  hour12: true,
});

export function formatDate(date) {
  if (!date) return '-';
  if (typeof date === 'string')
    date = new Date(date);
  else if (!date.getTime)
    return (typeof date)
  return shortDateFormatter.format(date);
}


// Full Date Formatter
//
// Wed, Dec 31, 5:00:00 PM
//
const fullDateFormatter = new Intl.DateTimeFormat('en-US', {
  weekday: 'short', 
  month: 'short', 
  day: 'numeric', 
  hour: 'numeric', 
  minute: '2-digit', 
  second: '2-digit', 
  hour12: true,
});



export function formatTimestamp(date, timezone=null) {
  if (date === undefined || date === null) 
    return '-';
  
  if (typeof date === 'string')
    date = new Date(date);
  else if (!date?.getTime)
    return `debug: cannot fmt ${(typeof date)} (${date})`
    
  let formatter;
  if (timezone !== null) {
    console.log(`Formatting with timezone: ${timezone}`)
    formatter = new Intl.DateTimeFormat('en-US', {
      weekday: 'short', 
      month: 'short', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: '2-digit', 
      second: '2-digit', 
      hour12: true,
      timeZone: timezone,
      // timeZoneName: 'short'
    });
  } else {
    formatter = fullDateFormatter;
  }
    
  return formatter.format(date);
}


// Meters to display distance
// This property is reactive to settings changes. Use like $fmt.distance(number)
//
// 1000 -> "0.62mi"
// 1000 -> "1.00km"
//
export function distance(meters) {
  return computed(() => {
    
    if (meters === undefined)
      return '-';

    if (settings.wantsMiles) {
      return `${(meters / 1609.344).toFixed(1)} mi`
    }
    else {
      return `${(meters / 1000).toFixed(1)} km`
    }

    //return `${meters}m`
  }).value
}

export function vert(meters) {
  return computed(() => {

    if (meters === undefined)
      return '-';

    if (settings.wantsMiles) {
      return `${Math.round(meters * 3.28084)} ft`
    }
    else {
      return `${Math.round(meters)} m`
    }

    //return `${meters}m`
  }).value
}

export function pace(m_s) {
  return computed(() => {

    if (m_s === undefined)
      return '-:-';

    let secondsPerUnit = 1 / m_s * (settings.wantsMiles ? 1609.344 : 1000);
    let {hours, minutes, seconds} = breakDuration(secondsPerUnit * 1000);
    
    if (undefined === minutes || undefined === seconds) {
      return '-:-';
    }
        
    // Always pad seconds.
    seconds = String(seconds || 0).padStart(2, '0');
    
    let fmt = '';
    if (hours) {
      fmt += hours;
      fmt += ':'
      minutes = String(minutes || 0).padStart(2, '0');
    }
    
    fmt += `${minutes || '-'}:${seconds}/${settings.wantsMiles ? 'mi' : 'km'}`
      
    return fmt;
  }).value
}

export function duration(ms) {
  console.warn("Use <duration-display>");
  return '0:00:00'
}

export function ordinal(place) {
  switch (Number(place)) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
}

export function pluralize(no, name) {
  if (Number(no) == 1) { return name };
  return `${name}s`
}

export default {
  date: formatDate,
  full: formatTimestamp,
  duration,
  distance,
  vert,
  pace,
  ordinal,
  pluralize,
}