import {normalize} from 'normalizr'


export function loadDetailAttrs(context, {url, entity, attributes}) {
  console.debug(`Load ${entity.name} detail attributes`, attributes);
  url += '?a=';
  url += attributes.join('+');

  if (entity.length === undefined)
    entity = [entity];
  
  return context.dispatch('loadEntities', {url, entity});
}


export function loadEntities(context, {url, entity, parent}) {
  if (!entity)
    return console.warn("Attempted to load with null entity", url);
  const entityName = entity.constructor == Array ? entity[0].key : entity.key;
  
  let promise = context.state.loading[url];
  if (promise) {
    console.log("store: Already loading %s; skipping", entityName);
    return promise;
  }

  context.commit('setLoadingStatus', {entityName, isLoading: true })
  
  promise = new Promise((resolve, reject) => {
    console.debug(`⬇️ Loading "${entityName}" (${url})`);
    // load what we've been asked for.
    this.axios.get(url)
    .catch((err) => {
      console.error(`Load error for ${entityName}`, err);
      reject(err);
    })
    .then((response) => {
      if (!response) {
        reject();
        return;
      }
      let data = normalize(response.data, entity);

      // Pass along the parent object to commitEntities
      data.parent = parent;

      // Handle case of empty response (response.data == [] )
      // We have a 200-series OK response here, so assume we're getting empty content
      // This way we create the correct key in the store instead of the application
      //  perpetually requesting a load for a nonexistant resource.
      //
      if (Object.keys(data.entities) < 1) {
        data.entities[entityName] = []
      }

      context.commit('commitEntities', data);
      delete context.state.loading[url];

      context.commit('setLoadingStatus', {entityName, isLoading: false});
      resolve(data);
    });
  });
  
  context.state.loading[url] = promise;
  return promise;
}

export function commitEntities(context, data) {
  return new Promise((resolve) => {
    // Had nextTick here for some reason
    data.onResolve = resolve;
    context.commit('commitEntities', data);
  });
}

export function deleteEntity(context, payload) {
  context.commit('deleteEntity', payload);
}

export default {
  commitEntities,
  deleteEntity,
  loadEntities,
  loadDetailAttrs,
}

